var generic;

generic = generic || {};

(function ($) {
  Drupal.behaviors.offersRoundelFormatterV1 = {
    attached: false,
    attach: function (context) {
      var $formatter = $('.js-offers-roundel', context);
      var $close = $('.offers-roundel__close', $formatter);
      var $body = $('body');
      var $roundel = $('.js-content-block-link', $formatter);
      var desktopTopValue = $formatter.data('desktopPositionTop');
      var desktopRightValue = $formatter.data('desktopPositionRight');
      var mobileTopValue = $formatter.data('mobilePositionTop');
      var mobileRightValue = $formatter.data('mobilePositionRight');
      var isMobile;
      var $html = $('html', context);
      var isRTL = $html.attr('dir') === 'rtl';
      var positionDir = isRTL ? 'left' : 'right';
      var offerCode = $formatter.find('.js-offer-code-text').val();
      var isOfferApplied = false;
      var cartData = null;
      var $copyCodeBtn;
      var $copiedCodeBtn;

      if (this.attached) {
        return;
      }
      this.attached = true;
      $formatter.draggable();

      $(document).on('slide-panel-block.close', function () {
        $body.removeClass('offers-slide-panel-open');
      });
      $(document).on('slide-panel-block.open', function () {
        $body.addClass('offers-slide-panel-open');
        generic.jsonrpc.fetch({
          method: 'trans.get',
          params: [
            {
              order_fields: ['offerCodes']
            }
          ],
          onSuccess: function (jsonResponse) {
            cartData = jsonResponse.getValue();
            $copyCodeBtn = $('.js-copy-code-btn');
            $copiedCodeBtn = $('.js-copied-code-btn');

            if (cartData.order && cartData.order.offerCodes) {
              offerCode = offerCode?.toLowerCase();
              isOfferApplied = ($.inArray(offerCode, cartData.order.offerCodes) > -1);
            }
            if (isOfferApplied) {
              $copyCodeBtn.addClass('hidden');
              $copiedCodeBtn.removeClass('hidden');
            } else {
              $copyCodeBtn.removeClass('hidden');
              $copiedCodeBtn.addClass('hidden');
            }
          }
        });
      });
      $close.on('click', function () {
        $.cookie('hide_offers_roundel', '1', { path: '/' });
        $(document).trigger('hide_offers_roundel');
        $('.js-offers-roundel-link').addClass('offers-roundel-hidden').removeClass('offers-roundel-display');
      });
      if (!generic.cookie('hide_offers_roundel')) {
        $('.js-offers-roundel-link').removeClass('offers-roundel-hidden').addClass('offers-roundel-display');
      }
      $(document).on('click', '.js-copy-code-btn', function () {
        var $this = $(this);
        var $copiedCta = $this.closest('.js-header-offers-panel-item').find('.js-copied-code-btn');

        generic.jsonrpc.fetch({
          method: 'offers.apply',
          params: [
            {
              offer_code: offerCode,
              do_not_defer_messages: 1
            }
          ],
          onBoth: function () {
            $this.addClass('hidden');
            $copiedCta.removeClass('hidden');
          }
        });
      });
      $roundel.each(function () {
        if ($(this).hasClass('offers-roundel-circle-size-small')) {
          $close.addClass('small-close');
        } else if ($(this).hasClass('offers-roundel-circle-size-medium')) {
          $close.addClass('medium-close');
        } else if ($(this).hasClass('offers-roundel-circle-size-large')) {
          $close.addClass('large-close');
        }
      });
      function getIsMobile() {
        var bps = Unison.fetch.all();
        var bp = Unison.fetch.now();

        return parseInt(bp.width, 10) < parseInt(bps.landscape, 10);
      }
      isMobile = getIsMobile();

      if (isMobile && Boolean(mobileTopValue) && Boolean(mobileRightValue)) {
        $formatter.css({
          top: mobileTopValue + 'px',
          [positionDir]: mobileRightValue + 'px'
        });
      } else if (Boolean(desktopTopValue) && Boolean(desktopRightValue)) {
        $formatter.css({
          top: desktopTopValue + 'px',
          [positionDir]: desktopRightValue + 'px'
        });
      }
    }
  };
})(jQuery);
